<script>
import helpers from '../../helpers'

export default {
  components: {
  },
  data() {
	return {
		dateStr:null,
		logs:[]
	};
  },
  computed: {
	orderedlog() {

		const logs = this.event?.log || [];
		logs.sort((a, b) => {
			return new Date(a.date) - new Date(b.date);
		});

		return logs ;
		
	}
	},
  methods: {
		...helpers,

		getUniques(array){
			if(!array || array.length == 0)
				return [];

			let aux = [];
			array.forEach( (ar) =>{
				if(aux.filter(a=> a.data.sku == ar.data.sku).length == 0){
					aux.push(ar);
				}
			})

			return aux;
		},

		getPercentageFromLog(type) {
			// eslint-disable-next-line no-case-declarations
			const viewsCount = this.event.log ? this.getUniques(this.event.log.filter(l => l.type == 'host_show_product')).length : 0;
			
			if(viewsCount == 0)
				return ''

			const count = this.event.log ? this.getUniques(this.event.log.filter(l => l.type == type)).length : 0;
			return `${Math.round((count / viewsCount)  * 100)} %` ;

		},

	
		getInfoFromLog(type){
			switch(type){
				case 'views_amount': 
					// eslint-disable-next-line no-case-declarations
					const viewsCount = this.event.log ? this.getUniques(this.event.log.filter(l => l.type == 'host_show_product')).length : 0; 

					if(viewsCount == 0) return this.$t('events_list.no_item_showed');
					else if(viewsCount == 1) return `${viewsCount} ${this.$t('events_list.item_showed')}`;
					else return `${viewsCount} ${this.$t('events_list.items_showed')}`;
				case 'detail_amount':
					// eslint-disable-next-line no-case-declarations
					const detailsCount = this.event.log ? this.getUniques(this.event.log.filter(l => l.type == 'customer_view_product')).length : 0;
					if(detailsCount == 0) return this.$t('events_list.no_item_seen');
					else if(detailsCount == 1) return `${detailsCount} ${this.$t('events_list.item_seen')}`;
					else return `${detailsCount} ${this.$t('events_list.items_seen')}`;
					
				case 'cart_amount':
						// eslint-disable-next-line no-case-declarations
					const cartCount = this.event.log ? this.getUniques(this.event.log.filter(l => l.type == 'customer_cart_product')).length : 0;
					if(cartCount == 0) return this.$t('events_list.no_item_cart');
					else if(cartCount == 1) return `${cartCount} ${this.$t('events_list.item_cart')}`;
					else return `${cartCount} ${this.$t('events_list.items_cart')}`;
					
				default:
					return type;
			}
	},

	getTextFromLog(type, log){
		
		switch(type){
			case 'host_entry': 
				return this.$t('events_list.log_host_entry');
			case 'customer_entry': 
				return this.$t('events_list.log_customer_entry');    
			case 'host_show_product':
				return  this.$t('events_list.log_host_show_product').replace('##',`${log.data.name} (${log.data.sku})`);
			case 'host_hide_product':
				return  this.$t('events_list.log_host_hide_product').replace('##',`${log.data.name} (${log.data.sku})`);
			case 'host_entry_error': 
				return this.$t('events_list.log_host_entry_error'); 
			case 'customer_entry_error': 
				return this.$t('events_list.log_customer_entry_error'); 
			case 'customer_view_product': 
				return this.$t('events_list.log_customer_view_product').replace('##',`${log.data.name} (${log.data.sku})`);
			case 'customer_cart_product':
				return this.$t('events_list.log_customer_cart_product').replace('##',`${log.data.name} (${log.data.sku})`).replace('###',log.data.quantity);
			case 'customer_cart_update_product':
				return this.$t('events_list.log_customer_cart_update_product').replace('##',`${log.data.name} (${log.data.sku})`).replace('###',log.data.quantity);
			case 'customer_cart_remove_product':
				return this.$t('events_list.log_customer_cart_remove_product').replace('##',`${log.data.name} (${log.data.sku})`);
			case 'customer_confirm_purchase':
				return this.$t('events_list.log_customer_confirm_purchase').replace('##',`${log.data.total_cart}`);
			case 'customer_exit':
				return this.$t('events_list.log_customer_exit');
			case 'host_exit':
				return this.$t('events_list.log_host_exit');
			case 'call_rating':
				return log.data.isAdmin ? this.$t('events_list.log_host_rate_call').replace('##',log.data.stars) : this.$t('events_list.log_customer_rate_call').replace('##',log.data.stars);
			default:
				return type;
		}
	},

	getEvaluationsBy(host){
		return this.event.log && this.event.log.length > 0 ? this.event.log.filter(l => l.type == 'call_rating' && l.data.isAdmin == host) : [];
	},

	hasEvaluations(type) {
		if(!type)
			return this.event.log && this.event.log.filter(l => l.type == 'call_rating').length > 0;
		else if(type=='host'){
			return this.event.log && this.event.log.filter(l => l.type == 'call_rating' && l.data.isAdmin).length > 0;
		}else{
			return this.event.log && this.event.log.filter(l => l.type == 'call_rating' && !l.data.isAdmin).length > 0;
		}
	},
},

  mounted() {
	
  },
  props: {
	event:{
		type: Object,
	},
  },
  emits: []
  
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<div>
					<p class="fw-medium mb-2">{{ $t('events_list.sold') }}:</p>
					<h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale(this.event.total_cart)}`">{{`$${parseMoneyReducedWithLocale(this.event.total_cart)}` }}</h4>
				</div>
			</div>
		
			<div class="row mt-4" >
				<b-tabs pills nav-class="bg-light rounded" content-class="mt-8">
					<b-tab :title="$t('events_list.info')" active>
						<div class="row mt-4">
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-user me-3 align-middle`"></i>
									{{this.event.invites[ 0 ].name + " - " + this.event.invites[ 0 ].email}}
								</div>
							</div>
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-briefcase-alt-2 me-3 align-middle`"></i>
									{{this.event.campaignName}} 
								</div>
							</div>
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-calendar me-3 align-middle`"></i>
									{{this.event.hostName}}
								</div>
							</div>

							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx bx-alarm me-3 align-middle`"></i>
									{{ `${parseDateWithLocale(this.event.date, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(this.event.date, {hour: '2-digit', minute:'2-digit'})}`}}
								</div>
							</div>
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-show-alt me-3 align-middle`"></i>
									{{ getInfoFromLog('views_amount')  }}
								</div>
							</div>
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-check-double me-3 align-middle`"></i>
									{{ getInfoFromLog('detail_amount')  }} 
									<span class="badge badge-soft-success font-size-10">{{getPercentageFromLog('customer_view_product')}}</span>
								</div>
							</div>
							<div class="row">
								<div class="col mb-3">
									<i :class="`font-size-20 bx bx-cart-alt me-3 align-middle`"></i>
									{{ getInfoFromLog('cart_amount')  }} 
									<span class="badge badge-soft-success font-size-10" v-if="getPercentageFromLog('customer_cart_product') != ''">{{ getPercentageFromLog('customer_cart_product') }}</span>
								</div>
							</div>
							
						</div>
					</b-tab>
					<b-tab :title="$t('events_list.log')">
						<div class="row mt-4 ">
							<div class="col">
								<ul class="verti-timeline list-unstyled">
									<li class="event-list" v-for="log in orderedlog" :key="log.date" >
										<div class="event-timeline-dot">
											<i class="bx bx-right-arrow-circle font-size-15"></i>
										</div>
										<div class="d-flex">
											<div class="me-3">
												<h5 class="font-size-12">
													{{`${parseDateWithLocale(log.date, { month: 'short', day: 'numeric'})} ${parseTimeWithLocale(log.date,{hour: '2-digit', minute:'2-digit'})}`}}
													<!--{{`${new Date(log.date).toLocaleDateString()} ${new Date(log.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}}-->
													<i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
												</h5>
											</div>
											<div class="flex-grow-1">
												<div>{{ getTextFromLog(log.type, log) }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</b-tab>
					<b-tab :title="$t('events_list.cart')" v-if="event.cart">
						<div class="row mt-4 ">
							<ul class="list-group list-group-flush">
								<p class="text-muted text-center" v-if="this.event.cart.items.length == 0">
									{{ 'No se encontraron items en el carrito' }}
								</p>

								<li class="list-group-item" v-for="item in this.event.cart.items" :key="item.data.id">
									<div class="d-flex">
									<div class="me-3" v-if="item.data.images && item.data.images.length > 0">
										<img
										:src="item.data.images[0].url"
										alt=""
										class="avatar-md h-auto d-block rounded"
										/>
									</div>

									<div class="align-self-center overflow-hidden me-auto">
										<div>
										<h5 class="font-size-14 text-truncate">
											<a href="#" class="text-dark">{{ item.data.name }}</a>
										</h5>
										<p class="text-muted mb-0">{{ item.data.count == 1 ? `$ ${item.data.price.toLocaleString('es')}` : `$ ${item.data.price.toLocaleString('es')} (${item.data.count})` }}</p>
										</div>
									</div>
									</div>
								</li>
							</ul>
						</div>
					</b-tab>
					<b-tab :title="$t('events_list.chat')">
						<div class="row mt-4">
							<ul class="list-group list-group-flush">
								<p class="text-muted text-center" v-if="this.event.chat.length == 0">
									{{ $t('events_list.chat_empty')  }}
								</p>
								<li class="list-group-item py-3" v-for="chat in this.event.chat" :key="chat.date">
									<div class="d-flex">
										<div class="flex-grow-1">
											<h5 class="font-size-14 mb-1">
												{{chat.name}}
												<small class="text-muted float-end">{{`${new Date(chat.date).toLocaleDateString()} ${new Date(chat.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}}</small>
											</h5>
											<p class="text-muted">
												{{ chat.message }}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</b-tab>
					<b-tab :title="$t('events_list.evaluation')">
						<div class="row mt-4">
							<ul class="list-group list-group-flush">
								<p class="text-muted text-center" v-if="!hasEvaluations()">
									{{ $t('events_list.evaluations_empty')  }}
								</p>
								<div v-if="hasEvaluations('host')">
									<h5 class="font-size-14 mb-1 py-3">
										{{ $t('events_list.evaluation_host')  }}
									</h5>
									<li class="list-group-item py-3"  v-for=" ev in getEvaluationsBy(true)" :key="ev.date">
										<div class="d-flex">
											<div class="flex-grow-1">
												<h5 class="font-size-14 mb-1">
													<i :class=" ev.data.stars == 5 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=4 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=3 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=2 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=1 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
												</h5>
												<p class="text-muted">
													{{ev.comment}}
												</p>
											</div>
										</div>
									</li>
								</div> 
								<div v-if="hasEvaluations('guest')">
									<h5 class="font-size-14 mb-1 py-3">
										{{ $t('events_list.evaluation_guest')  }}
									</h5>
									<li class="list-group-item py-3" v-for=" ev in getEvaluationsBy(false)" :key="ev.date" >
										<div class="d-flex">
											<div class="flex-grow-1">
												<h5 class="font-size-14 mb-1">
													<i :class=" ev.data.stars == 5 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=4 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=3 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=2 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
													<i :class=" ev.data.stars >=1 ? 'bx bxs-star float-end text-warning' : 'bx bx-star float-end' "></i>
												</h5>
												<p class="text-muted">
													{{ev.data.comment}}
												</p>
											</div>
										</div>
									</li>
								</div> 
								
							</ul>
						</div>
					</b-tab>
					<b-tab :title="$t('events_list.tags')">
						<div class="row mt-2">
							<ul class="list-group list-group-flush">
								<div>
									<h5 class="font-size-14 mb-1 py-3">
										{{$t("events_list.tags")}}
									</h5>
									<p class="text-muted text-center" v-if="!this.event.tags || this.event.tags.length == 0">
										{{$t("events_list.tags_empty")}}
									</p>
									<div v-if="this.event.tags && this.event.tags.length > 0">
										<span v-for="tag in this.event.tags" :key="tag.value" class="badge bg-primary"> {{ tag.value }} </span>
									</div>
								</div> 
								<div>
									<h5 class="font-size-14 mb-1 py-3">
										{{$t("events_list.comments")}}
									</h5>
									<p class="text-muted">
										{{this.event.comments}}
									</p>
								</div> 
							</ul>
						</div>
					</b-tab>
				</b-tabs>
			</div>
			
		</div>
	</div>
</template>

<style scoped>
.row {
	padding-right: 0px !important;
	--bs-gutter-x: 0 !important;
}
</style>
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import EventLogsModal from "@/components/events/event-log";
import FilterC from "@/components/events/filter";
import Drawer from "vue-simple-drawer";
import DatePicker from "vue2-datepicker";
import EmptyList from "@/components/widgets/empty_list"
import helpers from '../../../helpers'

import {
	liveshoppingAnalyticsMethods,
	analyticsMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Events",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
		Layout,
		PageHeader,
		EventLogsModal,
		FilterC,
		Drawer,
		DatePicker,
		EmptyList
  },
  data() {
	return {
		title: this.$t('events.title'),
		items: [],
		showModalHost: false,
		showModalProducts:false,
		isLoading: false,
		fullPage: true,
		canCancel: false,
		useSlot: false,
		color: "#007bff",
		bgColor: "#ffffff",
		height: 128,
		width: 128,
		lastSyncDate: '-',
		events:[],
		currentPage:1,
		rows:0,
		perPage:15,
		query: '',
		showModalEventLog:false,
		selectedEvent:null,
		filterVisible:false,
		countFilter:0,
		advancedQuery:'',
    openRightDrawer: false,
    dateFilter: null,
		filters:[],
    disabledBefore: new Date().setDate(new Date().getDate() - 90),
    shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, lastday];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],
			empty_config:{
					"title" : this.$t('events_list.empty_title'),
					"subtitle" : this.$t('events_list.empty_text'),
					"buttonText" : null,
					icon: "fa-cubes"
			},
    }
  },
  
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 
  },
  created() {
	
  },
  methods: {
	... liveshoppingAnalyticsMethods,
	... analyticsMethods,
  ...helpers,
	
	formatDate(d) {
      
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		const year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
		
		return [year, month, day].join('-');
	},

  rightDrawer(){
    this.openRightDrawer = false;
  },

	onDetailsClicked(event){
		this.selectedEvent = event.event;
		this.selectedEvent.total_cart = event.total_cart;
		this.selectedEvent.campaignName = event.campaign;
		this.selectedEvent.hostName = event.host;
		this.showModalEventLog = true;
	},
	applyFilter(query){

		if(this.filters.length > 0){
			query+= this.getCustomFilterQuery();
		}

		let loader = this.$loading.show();
		this.query = query;
		query+=`&limit=${this.perPage}`;

		this.currentPage = 1;
		this.isLoading = true;
		this.getEventsList(query).then((res)=>{
			this.events = res.events || [];
			if(res.total && res.total.length > 0){
				this.rows = res.total[0].total;
			}
			
		}).catch().finally(()=>{
			loader.hide();
			this.isLoading = false;
		});

	},

	loadEventsWithPaging(){

		let loader = this.$loading.show();
		const current = this.currentPage == 0 ? this.currentPage : this.currentPage - 1;
		let query = `${this.query}&limit=${this.perPage}&skip=${current * this.perPage}`

		if(this.filters.length > 0){
			query+= this.getCustomFilterQuery;
		}

		this.isLoading = true;
		this.getEventsList(query).then((res)=>{
			this.events = res.events || [];
			if(res.total && res.total.length > 0){
				this.rows = res.total[0].total;
			}
			
		}).catch().finally(()=>{
			loader.hide();
			this.isLoading = false;
		});

	},  
	onEventsListPageClicked(){
		this.loadEventsWithPaging();
	},

	getStatusText(status){
		if(status=='scheduled'){
			return this.$t('events_list.status_scheduled');
		}else if(status=='closed'){
			return this.$t('events_list.status_closed');
		}else if(status=='started'){
			return this.$t('events_list.status_started');
		}else if(status=='canceled'){
			return this.$t('events_list.status_canceled');
		}else if(status=='missed'){
			return this.$t('events_list.status_missed');
		}

		return status;
	},
	getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          q+= `&${f.key}=${f.value}`
        });
      }

      return q;
    },
		onFilterClicked(){
			this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
		},

		applyCustomFilter(){
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;
      
      this.filters = this.$refs.filterRef.getAllFilters();
      
      this.applyFilter(query)
     
      this.openRightDrawer = false;
    },
		onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      
      if(this.filters.length == 0)
        this.personalizationId = null;

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
      
    },
		removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },

},watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal && !this.personalizationId){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('events_list.title')" :items="items" />
		<div class="col-lg-12 mb-3">
			<div class="d-flex align-items-center">
			<div class="ms-3 flex-grow-1 mb-2">
				<div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.badge }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
      </div>
      </div>
			<div class="text-end w-50">
					<div class="row">
						<div class="col d-flex justify-content-end pe-0" >
							<ul class="nav nav-pills product-view-nav interact" v-on:click="onFilterClicked">
								<li class="nav-item">
									<a class="nav-link active" href="javascript: void(0);">
										<i class="bx bx-filter align-middle"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="col">
							<!--<p class="mb-0 text-start">{{$t('reco_analyze.date_range')}}</p>-->
							<date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date < new Date().setDate((new Date()).getDate() - 90)">
							</date-picker>    
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row">
			
			<div class="col-12">
				<div class="card mb-3">
					<div class="card-body p-0">
						<div class="row ">
							<EmptyList :config="empty_config" v-if="!isLoading && events.length == 0 " class="mt-3 mb-3"/>
							<div class="col-lg-12">
								<div class="table-responsive" v-if="!isLoading && events.length > 0 ">
								<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
										<thead class="table-light">
											<tr>
												<th scope="col">{{$t('events_list.name')}}</th>
												<!--<th scope="col">{{$t('events_list.campaign')}}</th>-->
												<th scope="col">{{$t('events_list.date')}}</th>
												<th scope="col">{{$t('events_list.host')}}</th>
												<th scope="col">{{$t('events_list.guest')}}</th>
												<th scope="col">{{$t('events_list.status')}}</th>
												<th scope="col">{{$t('events_list.sold')}}</th>
												<th scope="col"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="event in events" :key="event._id">
												<td style="max-width: 250px;" class="text-truncate" v-b-popover.hover.bottom="event.event?.title">{{event.event?.title}}</td>
												<!--<td style="width: 70px;">{{event.campaign}}</td>-->
												<td>{{ `${parseDateWithLocale(event.event?.date, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(event.event?.date, {hour: '2-digit', minute:'2-digit'})}`}}</td>
												<td>{{event.host}}</td>
												<td>{{ event.event?.invites && event.event?.invites.length > 0  ? event.event?.invites[0].email : ''}}</td>
												<td>
													<span class="badge font-size-10"
														:class="{
														'bg-success': `${event.event?.status}` === 'closed',
														'bg-warning': `${event.event?.status}` === 'scheduled',
														'bg-primary': `${event.event?.status}` === 'started',
														'bg-danger': `${event.event?.status}` === 'canceled',
														'bg-info': `${event.event?.status}` === 'missed',
														
													}">{{ getStatusText(event.event?.status) }}</span>
												</td>
												<td class="bold">
													<p class="mb-0" v-if="event.event?.status != 'scheduled' && event.event?.status != 'canceled'">{{`$${parseMoneyWithLocale(event.total_cart)}`}}</p>
												</td>
												<td class="text-end">
													<i class= 'bx bx-detail interact' v-on:click="onDetailsClicked(event)" v-if="event.event?.status != 'scheduled' && event.event?.status != 'canceled'" />
												</td>
											</tr>
										</tbody>
								</table>
								</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-3" v-if=" !isLoading && rows > perPage">
					<div class="col">
						<div class="dataTables_paginate paging_simple_numbers float-end">
							<ul class="pagination pagination-rounded mb-0">
								<b-pagination
									@input="onEventsListPageClicked"
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage">
								</b-pagination>
							</ul>
						</div>
					</div>
				</div>
		</div>
		<b-modal
			v-model="showModalEventLog"
			:title="selectedEvent?.title"
			title-class="text-black font-18"
			body-class="p-3"
			hide-footer
			scrollable>
			<EventLogsModal :event="selectedEvent"/>
		</b-modal>
    <Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
      <div v-if="openRightDrawer" class="h-100">
          <div class="offcanvas-header">
              <h4 class="offcanvas-title" id="offcanvasExampleLabel">
              <i class="bx bx-gear font-size-16 align-middle me-1"></i>
              {{$t('filter.title')}}
              </h4>
          </div>
          <hr class="mt-1" />
          <div class="offcanvas-body flex-grow-1 h-100">
            <div>
              <FilterC ref="filterRef"></FilterC>
            </div>
            <div class="position-fixed bottom-0 end-0 p-3">
              <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
              <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
            </div>
          </div>
        </div>
    </Drawer>
	</Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>